import { render, staticRenderFns } from "./ArtTripsPage.vue?vue&type=template&id=23cc8212&scoped=true&"
import script from "./ArtTripsPage.vue?vue&type=script&lang=js&"
export * from "./ArtTripsPage.vue?vue&type=script&lang=js&"
import style0 from "./ArtTripsPage.vue?vue&type=style&index=0&id=23cc8212&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23cc8212",
  null
  
)

export default component.exports