import DataFilterHelper from './DataFilterHelper';
import { FILTER_TYPE_DATES, FILTER_TYPE_DESTINATIONS } from '@/helpers/data-filter/DataFilterTypes';

class ArtTripDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_DATES]: [],
  };
  validValuesTypes = {
    array: [FILTER_TYPE_DESTINATIONS, FILTER_TYPE_DATES],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.dates
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
      }),
      this.addDateGroupItems({
        group: groupsWithItems.dates,
        itemsType: FILTER_TYPE_DATES,
        title: 'Dates',
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        destinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        date: transformed[FILTER_TYPE_DATES],
      },
    };
  }
}

export default ArtTripDataFilterHelper.create();
